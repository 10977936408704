import { FormControl, MenuItem, Select } from "@mui/material";
import FilterDropdown from "../../assets/FilterDropdown.svg";
import { useEffect, useRef, useState } from "react";
import DateFilterModal from "../DateFilterModal";
import "./style.css";
import { updateUrlWithFilters } from "../../utils/clearAllSelectedFilters";
import { dateFilterOptions } from "../../utils/constants";
import { getDateParamInUrl } from "../../utils/common_functions";

const DateFilters = ({
  setDateUserSelectedFilter,
  dateUserSelectedFilter,
  userSelectedFilter,
}) => {
  const [dateFilter, setDateFilter] = useState(
    getDateParamInUrl(userSelectedFilter) || dateFilterOptions[2]
  );
  const isCustomDateDropdownSelected = useRef(false);

  const handleDateFilterChange = (e) => {
    if (e.target.value === "Custom date")
      isCustomDateDropdownSelected.current = true;
    setDateFilter(e.target.value);
  };

  useEffect(() => {
    updateUrlWithFilters(
      userSelectedFilter,
      userSelectedFilter["spanKey"],
      userSelectedFilter["startDate"],
      userSelectedFilter["endDate"]
    );
  }, [dateFilter]);

  return (
    <div className="date-filters">
      <FormControl sx={{ m: 1, backgroundColor: "white" }}>
        <Select
          value={dateFilter}
          onChange={handleDateFilterChange}
          displayEmpty
          IconComponent={() => (
            <img
              loading="lazy"
              src={FilterDropdown}
              alt="dropDown"
              style={{ marginRight: "10px" }}
            />
          )}
          inputProps={{ "aria-label": "Without label" }}
        >
          {dateFilterOptions.map((item) => {
            return (
              <MenuItem
                value={item}
                key={item}
                sx={{
                  color: "#3C3E49",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "0.8rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "1.25rem",
                  letterSpacing: "0.00875rem",
                }}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <DateFilterModal
        modalHeading="Filter metrics by date"
        dateFilter={dateFilter}
        setDateUserSelectedFilter={setDateUserSelectedFilter}
        setDateFilter={setDateFilter}
        dateUserSelectedFilter={dateUserSelectedFilter}
        isCustomDateDropdownSelected={isCustomDateDropdownSelected}
        userSelectedFilter={userSelectedFilter}
      />
    </div>
  );
};

export default DateFilters;
