import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import "./style.css";
import FilterDropdown from "../../assets/FilterDropdown.svg";
import {
  formatDateValue,
  updateUrlWithFilters,
} from "../../utils/clearAllSelectedFilters";
import { findDashboardDateValue } from "../../utils/clearAllSelectedFiltersForDashboard";
import { Tooltip } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  minHeight: "55%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalHeadingStyle = {
  color: "#3C3E49",
  fontFamily: "Rubik,sans-serif",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "1.75rem",
  paddingBottom: "0.5rem",
};

const startEndDateStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const startDateStyle = {
  display: "inline-flex",
  justifyContent: "space-between",
  minWidth: "40%",
};

const startEndDateCustomInputStyle = {
  width: "12.25rem",
  height: "2rem",
  display: "inline-flex",
  alignItems: "center",
  paddingLeft: "0.30rem",

  borderRadius: "0.25rem",
  border: "2px solid #b2b3b8",
  background: "#FFF",
};

const startEndDateTextStyle = {
  display: "inline-flex",
  alignItems: "center",
  color: "#3C3E49",
  fontFamily: "Rubik,sans-serif",
  fontSize: "1.25 rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "1.25rem",
  letterSpacing: "0.00875rem",
};

const applyResetStyle = {
  marginTop: "20rem",
  display: "inline-flex",
  justifyContent: "flex-end",
  width: "100%",
};

const applyButtonStyle = {
  borderRadius: "0.5rem",
  background: "#00E676",
};

const resetButtonStyle = {
  borderRadius: "0.5rem",
  background: "#ECEDF0",
};

export default function DateFilterModal({
  modalHeading,
  dateFilter,
  setDateUserSelectedFilter,
  setDateFilter,
  dateUserSelectedFilter,
  isCustomDateDropdownSelected,
  userSelectedFilter,
}) {
  const [open, setOpen] = React.useState(false);

  const [startDate, setStartDate] = React.useState(
    userSelectedFilter["startDate"]
  );
  const [endDate, setEndDate] = React.useState(userSelectedFilter["endDate"]);

  const [modalButtonText, setModalButtonText] = React.useState("");

  const isDateChangedFromModal = React.useRef(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatStartEndDate = (dateString) => {
    const formattedDate = new Date(dateString);
    const day = formattedDate.getUTCDate().toString().padStart(2, "0");
    const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = formattedDate.getUTCFullYear().toString();

    return `${year}-${month}-${day}`;
  };

  const handleApply = (e) => {
    setDateFilter("Custom date");
    setDateUserSelectedFilter({
      From: startDate ? formatDateValue(startDate) : "",
      To: endDate ? formatDateValue(endDate) : "",
    });
    userSelectedFilter["startDate"] = formatDateValue(startDate);
    userSelectedFilter["endDate"] = formatDateValue(endDate);
    updateUrlWithFilters(
      userSelectedFilter,
      "Custom date",
      formatDateValue(startDate),
      formatDateValue(endDate)
    );
    handleClose();
  };

  const getTextFromDate = (dateString, options) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";
    return date.toLocaleDateString("en-US", options);
  };

  const getFormattedModalDateString = (dateString) => {
    const [month, day] = dateString.split(" ");

    const convertedMonth =
      month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();

    return `${convertedMonth} ${day}`;
  };

  const getModalText = () => {
    const startDateText = getTextFromDate(dateUserSelectedFilter.From, {
      month: "short",
      day: "2-digit",
    });
    const endDateText =
      getTextFromDate(dateUserSelectedFilter.To, {
        month: "short",
        day: "2-digit",
      }).length > 0
        ? ` - ${getFormattedModalDateString(
            getTextFromDate(dateUserSelectedFilter.To, {
              month: "short",
              day: "2-digit",
            })
          )}`
        : "";

    return [startDateText, endDateText].join("");
  };

  const urlParams = new URLSearchParams(window.location.search);

  const modalWindowDatesSet = (dateFilter) => {
    userSelectedFilter["spanKey"] = dateFilter;

    switch (dateFilter) {
      case "Today":
      case "Yesterday":
        setStartDate(findDashboardDateValue(dateFilter));
        setEndDate(null);

        userSelectedFilter["startDate"] = findDashboardDateValue(dateFilter);
        userSelectedFilter["endDate"] = "";
        break;
      case "Last 7 days":
      case "Last 14 days":
      case "Last 30 days":
        setStartDate(findDashboardDateValue(dateFilter));
        setEndDate(findDashboardDateValue("Today"));
        userSelectedFilter["startDate"] = findDashboardDateValue(dateFilter);
        userSelectedFilter["endDate"] = findDashboardDateValue("Today");
        break;
      case "Custom date":
        setStartDate(
          dateUserSelectedFilter.From.length > 0
            ? formatStartEndDate(dateUserSelectedFilter.From)
            : urlParams.get("custom_date_from")
        );
        setEndDate(
          dateUserSelectedFilter.To.length > 0
            ? formatStartEndDate(dateUserSelectedFilter.To)
            : urlParams.get("custom_date_to")
        );
        userSelectedFilter["startDate"] =
          dateUserSelectedFilter.From.length > 0
            ? formatStartEndDate(dateUserSelectedFilter.From)
            : urlParams.get("custom_date_from");
        userSelectedFilter["endDate"] =
          dateUserSelectedFilter.To.length > 0
            ? formatStartEndDate(dateUserSelectedFilter.To)
            : urlParams.get("custom_date_to");
        break;
    }
  };

  const handleReset = (e) => {
    modalWindowDatesSet(dateFilter);
  };

  const getMinMaxDate = (dateString, noOfdays) => {
    const date = new Date(dateString);

    date.setDate(date.getDate() + noOfdays);

    return date;
  };

  const minEndDate = startDate
    ? new Date(new Date(startDate).getTime() + 30 * 24 * 60 * 60 * 1000)
    : null;

  React.useEffect(() => {
    if (isCustomDateDropdownSelected.current) {
      handleOpen();
      isCustomDateDropdownSelected.current = false;
    }
    modalWindowDatesSet(dateFilter);
  }, [dateFilter, urlParams]);

  React.useEffect(() => {
    if (!isDateChangedFromModal.current) {
      setDateUserSelectedFilter({
        From: startDate || "",
        To: endDate || "",
      });
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    setModalButtonText(getModalText());
  }, [dateUserSelectedFilter, startDate, endDate]);

  return (
    <div className="open_modal_container">
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          padding: "0.5rem",
          color: "#3c3e49",
          font: "400 14px/143% Rubik, sans-serif",
        }}
      >
        {modalButtonText}
        <img loading="lazy" src={FilterDropdown} alt="dropDown" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={modalHeadingStyle}
          >
            {modalHeading}
          </Typography>
          <Box sx={startEndDateStyle}>
            <Box sx={startDateStyle} className="startDateBoxContainer">
              <Typography
                className="startEndDateText"
                style={startEndDateTextStyle}
              >
                Start Date
              </Typography>
              <DatePicker
                open
                selected={startDate}
                customInput={
                  <div
                    className="start-end-date-picker-value"
                    style={startEndDateCustomInputStyle}
                  >
                    {startDate
                      ? getTextFromDate(startDate, {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : startDate}
                  </div>
                }
                onChange={(newDate) => {
                  isDateChangedFromModal.current = true;
                  setStartDate(newDate);
                  userSelectedFilter["Custom date"].From =
                    formatStartEndDate(newDate);
                }}
              />
            </Box>
            <Box sx={startDateStyle} className="endDateBoxContainer">
              <Typography
                className="startEndDateText"
                style={startEndDateTextStyle}
              >
                End Date
              </Typography>
              <DatePicker
                id="end_date_date_picker"
                open
                selected={endDate}
                minDate={startDate ? getMinMaxDate(startDate, 1) : null}
                maxDate={startDate ? getMinMaxDate(startDate, 30) : null}
                customInput={
                  <div
                    className="start-end-date-picker-value"
                    style={startEndDateCustomInputStyle}
                  >
                    {endDate
                      ? getTextFromDate(endDate, {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })
                      : endDate}
                  </div>
                }
                onChange={(newDate) => {
                  isDateChangedFromModal.current = true;
                  setEndDate(newDate);
                  userSelectedFilter["Custom date"].To =
                    formatStartEndDate(newDate);
                }}
                renderDayContents={(day, date) => {
                  if (minEndDate && date > minEndDate)
                    return (
                      <Tooltip
                        title="Select a range less than 30 days"
                        placement="bottom"
                        arrow
                        sx={{
                          "& .MuiTooltip-tooltip": {
                            marginTop: "0px !important",
                            borderRadius: "0.1875rem",
                            background: "var(--Base-Blue-grey-10, #3C3E49)",
                          },
                        }}
                      >
                        {day}
                      </Tooltip>
                    );
                  return day;
                }}
              />
            </Box>
          </Box>
          <Box sx={applyResetStyle} className="btnsContainerForBox">
            <Button style={applyButtonStyle} onClick={handleApply}>
              Apply
            </Button>
            <Button style={resetButtonStyle} onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
