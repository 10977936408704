import { isGuardianUser, isSuperUser } from "./common_functions";
import { findDashboardDateValue } from "./clearAllSelectedFiltersForDashboard";

export const checkIfTeamIsPresent = (teamValue, teamCollection) => {
  if (teamCollection.includes(teamValue[0])) return teamValue;
  else return [];
};

export const findUserSelectedParams = (allFilters) => {
  if (allFilters === undefined) return {};

  let dict = {};

  if (isGuardianUser() && !isSuperUser()) {
    const teamIDs = localStorage.getItem("teams");
    const teamCollection =
      teamIDs?.length > 0
        ? teamIDs.split(",").map((team) => parseInt(team))
        : [];

    dict = {
      ...dict,
      teams: teamCollection,
    };
  }

  return dict;
};
