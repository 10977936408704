import { Skeleton } from "@mui/material";
import { updateDateToHumanFormat } from "./common_functions";
import Paper from "@mui/material/Paper";

export const findCheckedStatusFromUrlParams = (tempArr, id, parentKey) => {
  return parentKey === "teams"
    ? tempArr.includes(parseInt(id))
    : tempArr.includes(id);
};

export const findSuboptions = (subObjArr, parentKey, subOptionsFromParam) => {
  return subObjArr.map((sub) => {
    return {
      type: sub.name,
      colour: sub.color_code,
      id: sub.id,
      checked: subOptionsFromParam
        ? findCheckedStatusFromUrlParams(subOptionsFromParam, sub.id, parentKey)
        : false,
      parent: parentKey,
    };
  });
};

export const isParentFilterSelected = (item, urlParams) => {
  if (item in urlParams) {
    return urlParams[item].length > 0;
  } else return false;
};

export const findFilterOptionsForDashboard = (
  filterObj,
  urlParams,
  urlParams2
) => {
  if (!filterObj) {
    console.error("filterObj is undefined or null");
    return []; // or some default value or behavior
  }

  const combinedParams = { ...urlParams, ...urlParams2 };

  const temp = Object.keys(filterObj).map((item) => {
    return {
      type: item,
      checked: isParentFilterSelected(item, combinedParams),
      isHighlighted: false,
      suboptions: findSuboptions(filterObj[item], item, combinedParams[item]),
      showsuboptions: false,
    };
  });

  return temp;
};

export const updateFirstResponseParam = (paramString) => {
  if (paramString)
    return paramString.replace("&", "?").concat("&ticket_type=closed");
};

export const findSlaData = (data) => {
  const slaData = [];

  data.forEach((item) => {
    slaData.push({
      name: item.sla_status,
      count: item.count,
      percent: item.percent,
    });
  });

  if (slaData.length === 0) {
    return slaData.concat([
      {
        name: "Achieved",
        count: 0,
        percent: 0,
      },
      {
        name: "Breached",
        count: 0,
        percent: 0,
      },
    ]);
  }

  const slaType = slaData[0].name;

  if (slaData.length === 1 && slaType === "Achieved") {
    return slaData.concat({
      name: "Breached",
      count: 0,
      percent: 0,
    });
  } else if (slaData.length === 1 && slaType === "Breached") {
    const achievedSla = {
      name: "Achieved",
      count: 0,
      percent: 0,
    };
    return [achievedSla, ...slaData];
  }

  return slaData;
};

export const getSubFilterClassName = (filterType) => {
  if (filterType !== "dashboardDate")
    return "filter_dropdown_child_options sub_filter_max_height";
  else return "filter_dropdown_child_options";
};

export const getParentFilterOption = (filterName) => {
  if (filterName === "DashboardDate") return "Date";
  else return filterName;
};
export const checkClosedBarDataKey = (
  closedBarData,
  legendKeysWithNonZeroData
) => {
  for (const closedBarDataKey in closedBarData) {
    if (closedBarDataKey != "name" && closedBarData[closedBarDataKey] > 0) {
      legendKeysWithNonZeroData.add(closedBarDataKey);
    }
  }
};

export const findLegendKeysWithNonZeroData = (formattedClosedBarData) => {
  const legendKeysWithNonZeroData = new Set();
  for (const closedBarData of formattedClosedBarData) {
    checkClosedBarDataKey(closedBarData, legendKeysWithNonZeroData);
  }

  return Array.from(legendKeysWithNonZeroData);
};

export const checkIfLegendKeyExists = (legendKey, nonZeroLegendKeys) => {
  let isLegendKeyExists = false;
  nonZeroLegendKeys.forEach((nonZerolegendKey) => {
    if (legendKey === nonZerolegendKey) isLegendKeyExists = true;
  });

  return isLegendKeyExists;
};

export const findLegendsToBeDisplayed = (legends, formattedClosedBarData) => {
  const nonZeroLegendKeys = findLegendKeysWithNonZeroData(
    formattedClosedBarData
  );

  const legendForNonZeroValues = [
    {
      ...legends[0],
      data: legends[0].data.filter((legendData) =>
        checkIfLegendKeyExists(legendData.id, nonZeroLegendKeys)
      ),
    },
  ];

  return legendForNonZeroValues;
};

export const convertToUpperCase = (str) => str.toUpperCase();
export const formatDuration = (duration) => duration * 1.1574e-5;
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const filterDataByDuration = (data) => {
  return data.filter((entry) => entry.duration > 0);
};

export const formatStatusName = (str) => {
  let formattedString = str?.replace(/_/g, " ");
  formattedString = formattedString?.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );

  const words = formattedString?.split(" ");
  words?.pop();

  return words?.join("-");
};

export const formatStatusData = (data) => {
  const formattedData = data.map((entry) => ({
    name: formatStatusName(entry.status),
    Pending: 0,
    "L1-Pickup": 0,
    "L2-Pickup": 0,
    "L3-Pickup": 0,
    "In-Progress": 0,
    Reassign: 0,
    "Pending-Dependency": 0,
    Review: 0,
  }));

  formattedData.forEach((entry) => {
    const apiEntry = data.find(
      (apiEntry) => formatStatusName(apiEntry.status) === entry.name
    );
    if (apiEntry) {
      const statusName = formatStatusName(apiEntry.status);
      entry[statusName] = formatDuration(apiEntry.adjustedValue);
      entry.actualValue = formatDuration(apiEntry.duration);
    }
  });
  return formattedData;
};

export const formatSquadData = (data) => {
  const formattedData = data.map((entry) => ({
    name: convertToUpperCase(entry.squad),
    L1: 0,
    L2: 0,
    L3: 0,
  }));

  formattedData.forEach((entry) => {
    const apiEntry = data.find(
      (apiEntry) => convertToUpperCase(apiEntry.squad) === entry.name
    );
    if (apiEntry) {
      const squadName = convertToUpperCase(apiEntry.squad);
      entry[squadName] = formatDuration(apiEntry.adjustedValue);
      entry.actualValue = formatDuration(apiEntry.duration);
    }
  });

  return formattedData;
};

export const calculateMaxValue = (data) => {
  return Math.max(...data.map((item) => item.duration));
};

export const adjustDataForVisualization = (data, maxValue) => {
  return data.map((item) => {
    const adjustedValue =
      item.duration < maxValue / 20 ? maxValue / 20 : item.duration;
    return { ...item, adjustedValue };
  });
};

export const formatClosedData = (data) => {
  const formattedData = data.map((entry) => ({
    name: formatDate(entry.date.substring(0, 10)),
    l1: 0,
    l2: 0,
    l3: 0,
    auto: 0,
    internal: 0,
    external: 0,
  }));

  formattedData.forEach((entry) => {
    const apiEntry = data.find(
      (apiEntry) => formatDate(apiEntry.date.substring(0, 10)) === entry.name
    );
    if (apiEntry) {
      apiEntry.data.data.forEach((squadData) => {
        entry[squadData?.squad_name?.toLowerCase()] = squadData.percent;
      });
    }
  });

  return formattedData;
};

export const isDataEmpty = (data, key) => {
  if (!data || data.length === 0) return false;
  return data.every((entry) => entry[key] === 0);
};

export const isClosedDataEmpty = (data) => {
  if (!data || !Array.isArray(data)) {
    return false;
  }
  return data.every((entry) => entry?.data?.data?.length === 0);
};

export const calculateBarLabel = (item, maxValue) => {
  if ((item.value * maxValue) / 20 === 0.0) {
    return null;
  }
  const hours = item.data.actualValue * 24;
  if (hours >= 24) {
    const days = hours * 0.04166675;
    return `${days.toFixed(2)} d`;
  } else if (hours * 3600 <= 59) {
    const secs = hours * 3600;
    return `${secs.toFixed(2)} sec`;
  } else if (hours <= 1) {
    const mins = hours * 60;
    return `${mins.toFixed(2)} min`;
  } else {
    return `${hours.toFixed(2)} hr`;
  }
};

const convertToReadableFormat = (input, isSeconds = true) => {
  let seconds;

  if (isSeconds) {
    seconds = input;
  } else {
    seconds = input * 24 * 3600;
  }

  const hours = seconds / 3600;

  let unit, value;
  if (hours >= 24) {
    unit = "days";
    value = hours / 24;
  } else if (seconds <= 59) {
    unit = "sec";
    value = seconds;
  } else if (hours <= 1) {
    unit = "mins";
    value = seconds / 60;
  } else {
    unit = "hrs";
    value = hours;
  }

  return `${value.toFixed(2)} ${unit}`;
};

export const CustomItemTooltipContentStatus = (input) => {
  const readableDuration = convertToReadableFormat(
    input.data.actualValue,
    false
  );

  return (
    <Paper sx={{ padding: 2, backgroundColor: "#000000", color: "#ffffff" }}>
      <p>{readableDuration}</p>
    </Paper>
  );
};

export const CustomItemTooltipContentTeam = (input, closedBarData) => {
  const item = input.index;

  const squadName = input.id;
  const backgroundColor = input.color;

  const dataEntry = closedBarData[item];
  const squadData = dataEntry
    ? dataEntry.data.data.find(
        (squad) => squad?.squad_name?.toLowerCase() === squadName
      )
    : null;
  const count = squadData ? squadData.count : 0;
  const percentage = squadData ? squadData.percent : 0;

  return (
    <Paper
      sx={{ padding: 2, backgroundColor: backgroundColor, color: "#000000" }}
    >
      <p>{percentage}%</p>
      <p>{count} tickets </p>
    </Paper>
  );
};

export const getLoader = () => {
  return <Skeleton variant="rounded" width={"100%"} height={"20vh"} />;
};

export const getStatusRows = (data) =>
  data.map((data) => ({
    clickup_task_id: data.clickup_task_id,
    task_title: data.task_title,
    pending_status_duration: convertToReadableFormat(
      data.pending_status_duration,
      true
    ),
    l1_pickup_status_duration: convertToReadableFormat(
      data.l1_pickup_status_duration,
      true
    ),
    l2_pickup_status_duration: convertToReadableFormat(
      data.l2_pickup_status_duration,
      true
    ),
    l3_pickup_status_duration: convertToReadableFormat(
      data.l3_pickup_status_duration,
      true
    ),
    in_progress_status_duration: convertToReadableFormat(
      data.in_progress_status_duration,
      true
    ),
    reassign_status_duration: convertToReadableFormat(
      data.reassign_status_duration,
      true
    ),
    pending_dependency_status_duration: convertToReadableFormat(
      data.pending_dependency_status_duration,
      true
    ),
    review_status_duration: convertToReadableFormat(
      data.review_status_duration,
      true
    ),
    status: data.status,
    sla_status: data.sla_status,
    team: data.team,
    sub_team: data.sub_team === null ? "N/A" : data.sub_team,
    created_at: updateDateToHumanFormat(data.created_at),
    completed_at:
      data.completed_at === null
        ? "N/A"
        : updateDateToHumanFormat(data.completed_at),
  }));

export const getLevelRows = (data) =>
  data.map((data) => ({
    clickup_task_id: data.clickup_task_id,
    task_title: data.task_title,
    l1_squad_duration: convertToReadableFormat(data.l1_squad_duration, true),
    l2_squad_duration: convertToReadableFormat(data.l2_squad_duration, true),
    l3_squad_duration: convertToReadableFormat(data.l3_squad_duration, true),
    sla_status: data.sla_status,
    team: data.team,
    sub_team: data.sub_team === null ? "N/A" : data.sub_team,
    created_at: updateDateToHumanFormat(data.created_at),
    completed_at:
      data.completed_at === null
        ? "N/A"
        : updateDateToHumanFormat(data.completed_at),
  }));

export const getSquadRows = (data) =>
  data.map((data) => ({
    clickup_task_id: data.clickup_task_id,
    task_title: data.task_title,
    closed_by_squad: data.closed_by_squad,
    closed_by_user: data.closed_by_user,
    sla_status: data.sla_status,
    team: data.team,
    sub_team: data.sub_team === null ? "N/A" : data.sub_team,
    created_at: updateDateToHumanFormat(data.created_at),
    completed_at:
      data.completed_at === null
        ? "N/A"
        : updateDateToHumanFormat(data.completed_at),
  }));
