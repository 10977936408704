export const isAuthenticated = localStorage.getItem("access_token");

export const supportedLevelsByGuardian = ["L1", "L2", "L3"];

export const tooltip_message = {
  slack_channel_queries:
    "Slack ID of channel where Guardian will provide auto responses to ticket status requests",
  daily_summary_channel:
    "Slack ID of channel where Guardian will provide daily SLA ticket summary updates",
  daily_summary_frequency:
    "Time(s) Guardian will provide SLA ticket summary updates",
  escalation_notes_required:
    "Investigation notes must be added to the ticket in order to escalate a ticket",
  resolution_notes_required: "Resolution notes required",
  sub_team_custom_field: "Clickup id of the sub team dropdown custom field",
  enforce_sub_team_level: "Enable sub teams for a specific support level",
};

export const dateFilterOptions = ['Today','Yesterday','Last 7 days','Last 14 days','Last 30 days','Custom date']
